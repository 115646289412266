import React from "react";

import Head from "next/head";
import { useTranslation } from "next-i18next";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { SignUpModal } from "./SignUpModal";

import { RouteGuard } from "./RouteGuard";
import { CLUB_NAVBAR } from "../../constants/headerTypes";
import { ScrollToAnchor } from "../../hooks/ScrollToAnchor";
import Script from "next/script";
import { HeaderTestTitle } from "./HeaderTestTitle/HeaderTestTitle";
import dynamic from "next/dynamic";

const SmartBanner = dynamic(() => import("./SmartBanner"));
const Notification = dynamic(() => import("./Notification"));

export const Layout = ({ children, navbar, foobar, tags, authProtected, additionalQueryFields }) => {
  const [t, { language }] = useTranslation();
  const {
    tagTitle,
    tagDescription,
    tagKeywords,
    alternate,
    ogType,
    ogSiteName,
    ogTitle,
    ogDescription,
    ogImage,
    ogImageWidth,
    ogImageHeight,
    ogImageAlt,
    ogImageType,
    ogUrl,
    icon,
    titleShort,
    title,
    iconTouch,
    googleTag,
    isMainClub,
    applicationGooglePlay,
    applicationAppStore,
  } = tags;

  const isNotClubPage = navbar !== CLUB_NAVBAR;
  const hasOgDescription = !!ogDescription;
  return (
    <>
      <Head>
        {tagTitle && <title>{tagTitle}</title>}
        {tagDescription && <meta name="description" content={tagDescription} />}

        {tagKeywords && <meta name="keywords" content={tagKeywords} />}

        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {alternate && <link rel="alternate" hrefLang={language} href={alternate} />}
        <link rel="shortcut icon" href={icon} />

        <meta name="theme-color" content="#000000" />
        <meta name="robots" content={/^true$/i.test(process.env.ENABLE_ROBOTS) ? "index" : "noindex"} />
        <link rel="apple-touch-icon" href={iconTouch} />
        {/* 
      <link
        crossOrigin="use-credentials"
        rel="manifest"
        href="/manifest.json"
      /> */}

        {ogImage && (
          <>
            <meta property="og:type" content={ogType} key="type" />
            <meta property="og:site_name" content={ogSiteName} key="site_name" />
            <meta property="og:title" content={ogTitle} key="title" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={ogTitle} />
            <meta name="twitter:image" content={ogImage} />
            {hasOgDescription && <meta name="twitter:description" content={ogDescription} />}

            {hasOgDescription && <meta property="og:description" content={ogDescription} key="description" />}

            <meta property="og:url" content={ogUrl} />
            <meta property="og:image" content={ogImage} key="image" />
            <meta property="og:image:alt" content={ogImageAlt} />
            <meta property="og:image:width" content={ogImageWidth} />
            <meta property="og:image:height" content={ogImageHeight} />
            <meta property="og:image:type" content={ogImageType} />
          </>
        )}
      </Head>
      {googleTag && (
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${googleTag}');
    `}
        </Script>
      )}

      {googleTag && (
        <Script id="google-tag-manager-frame" strategy="lazyOnload">
          {`
      (function(d,ns,f,i){
      var n=d.createElement(ns),j=d.createElement(f); j.style.height="0";j.style.width="0";j.style.display="none";j.style.visibility="hidden"; j.src=
      'https://www.googletagmanager.com/ns.html?id='+i;n.appendChild(j); d.body.prepend(n);
      })(document,'noscript','iframe','${googleTag}');
    `}
        </Script>
      )}

      {/* {googleTag && (
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTag}" height="0" width="0" style="display: none; visibility: hidden;" />`,
        }}
      />
    )} */}

      <RouteGuard authProtected={authProtected}>
        <>
          {isNotClubPage && <ScrollToAnchor />}
          {isMainClub && (applicationGooglePlay || applicationAppStore) && (
            <SmartBanner
              title={titleShort}
              titleShort={title}
              icon={icon}
              applicationGooglePlay={applicationGooglePlay}
              applicationAppStore={applicationAppStore}
            />
          )}
          <Header navbar={navbar} additionalQueryFields={additionalQueryFields} />
          {process.env.PRODUCTION !== "true" && <HeaderTestTitle>{t("test_site_text")}</HeaderTestTitle>}
          <main>{children}</main>
          <Footer foobar={foobar} />
          <SignUpModal />
          <Notification />
        </>
      </RouteGuard>
    </>
  );
};
