import {
  SET_ERROR_PAGE,
  SET_AUTHORIZATION,
  SET_CITIES,
  SET_CLUB_COLORS,
  SET_NOTIFICATION,
  RESET_NOTIFICATION,
  RESET_AUTHORIZATION,
  RESET_PASS_AUTHORIZATION,
} from "../types";

export const setErrorPage = ({ isError, message = null, status = null, statusText = null }) => {
  return {
    type: SET_ERROR_PAGE,
    isError,
    message,
    status,
    statusText,
  };
};

// если регистрация происходит в клубе, обязательные поля:

export const setAuthorization = ({ nextUrl = null, isSms = true, setHeader = false, isEmailReset, finishFunc = () => {} } = {}) => {
  return {
    type: SET_AUTHORIZATION,
    nextUrl,
    isEmailReset,
    isSms: isEmailReset ? false : isSms,
    setHeader,
    finishFunc,
  };
};

export const resetPassAuthorization = ({ nextUrl = null, resetToken } = {}) => {
  return {
    type: RESET_PASS_AUTHORIZATION,
    resetToken,
    nextUrl,
  };
};

export const resetAuthorization = () => {
  return {
    type: RESET_AUTHORIZATION,
  };
};

export const setCities = (cities) => ({
  type: SET_CITIES,
  cities,
});

export const setClubColors = ({ primaryColor, primaryTextColor, secondaryColor, secondaryTextColor }) => ({
  type: SET_CLUB_COLORS,
  primaryColor,
  primaryTextColor,
  secondaryColor,
  secondaryTextColor,
});

export const setNotification = ({ isError, notification = [] }) => ({
  type: SET_NOTIFICATION,
  isError,
  notification,
});

export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
});
