import { load } from "redux-localstorage-simple";

import { SET_AUTHORIZATION, RESET_AUTHORIZATION, RESET_PASS_AUTHORIZATION } from "../types";

const DEFAULT_STATE = {
  showAuthorization: false,
  setHeader: false,
  isSms: true,
  nextUrl: null,
  resetToken: null,
  isEmailReset: false,
  finishFunc: () => null,
};

const LOCAL_STATES = load({
  states: ["authorization"],
  namespace: "page-data",
  disableWarnings: true,
  preloadedState: { authorization: { ...DEFAULT_STATE } },
});

// setHeader - для передачи нужного slug после авторизации
// (чтобы после авторизации открылось нужная модалка)
// isEmailReset - для переброса на изменение пароля в модалке авторизации
const authorization = (state = LOCAL_STATES.authorization, { type, nextUrl, isEmailReset, isSms, resetToken, setHeader, finishFunc }) => {
  switch (type) {
    case SET_AUTHORIZATION:
      return {
        ...DEFAULT_STATE,
        showAuthorization: true,
        nextUrl,
        isSms,
        isEmailReset,
        setHeader,
        finishFunc,
      };

    case RESET_PASS_AUTHORIZATION:
      return {
        ...DEFAULT_STATE,
        showAuthorization: true,
        isSms: false,
        isEmailReset: true,
        resetToken,
        finishFunc,
      };

    case RESET_AUTHORIZATION:
      return DEFAULT_STATE;

    default:
      return state;
  }
};

export default authorization;
