import React, { createContext, useContext, useState, useEffect, useCallback, SetStateAction, Dispatch } from "react";

export const isServer = () => typeof window === "undefined";

const initialAuthState = {
  accessToken: null,
  refreshToken: null,
  isAuthorized: false,
  isReady: false,
  // discoverClubRequired: false, // Новый параметр для проверки необходимости Discover
};

const initialFullState = {
  accessToken: null,
  refreshToken: null,
  isAuthorized: false,
  isReady: true,
  // discoverClubRequired: false,
};

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthorized: boolean;
  isReady: boolean;
  // discoverClubRequired: boolean; // Параметр для проверки
}

export interface AuthContextProps extends AuthState {
  signIn: (tokens: Tokens, updateStorage?: boolean) => void;
  signOut: () => void;
  // loadUserData: () => void;
  // setIsLoading: Dispatch<SetStateAction<boolean>>;
  // isLoading: boolean; // Глобальная загрузка
  // redirectTo: (url: string) => void;
}

interface Tokens {
  accessToken: string | null;
  refreshToken: string | null;
}

// const AuthContext = createContext<AuthContextProps | null>(null);

const AuthContext = createContext<AuthContextProps>({
  ...initialAuthState,
  // isLoading: false,
  // redirectTo: () => null,
  // setIsLoading: () => null,
  // loadUserData: () => null,
  signIn: () => null,
  signOut: () => null,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useProviderAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);

export const useProviderAuth = () => {
  const [authState, setAuthState] = useState<AuthState>(initialAuthState);

  useEffect(() => {
    const tokensData = localStorage.getItem("tokens");

    if (tokensData) {
      const { accessToken, refreshToken } = JSON.parse(tokensData);

      setAuthState({
        accessToken,
        refreshToken,
        isAuthorized: true, // Авторизация включена
        isReady: true, // Данные пользователя еще не загружены
      });
      return;
    }

    setAuthState(initialFullState);
  }, []);

  const signIn = useCallback(
    ({ accessToken, refreshToken }: { accessToken: string | null; refreshToken: string | null }, updateStorage = true) => {
      const tokensData = {
        accessToken,
        refreshToken,
        // exp: Date.now() + 5 * 60 * 1000, //add 5 mins
        // isAuthorized: true,
      };

      if (updateStorage) localStorage.setItem("tokens", JSON.stringify(tokensData));

      setAuthState({
        accessToken,
        refreshToken,
        isAuthorized: true,
        isReady: true,
      });
    },
    []
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("tokens");

    setAuthState(initialFullState);
  }, []);

  return {
    ...authState,
    signIn,
    signOut,
    // loadUserData,
    // redirectTo,
  };
  // <AuthContext.Provider
  //   value={{
  //     ...authState,
  //     signIn,
  //     signOut,
  //     setIsLoading,
  //     isLoading,
  //     // loadUserData,
  //     // redirectTo,
  //   }}
  // >
  //   {children}
  // </AuthContext.Provider>;
};

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }
//   return context;
// };
