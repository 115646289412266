import React from "react";
import {
  DEFAULT_NAVBAR,
  CLUB_NAVBAR,
  EMPTY_NAVBAR,
  PORTAL_NAVBAR,
  LANDING_NAVBAR,
} from "../../../constants/headerTypes";
import dynamic from "next/dynamic";

const ClubsNavBar = dynamic(() => import("./ClubsNavBar"));
const PortalNavBar = dynamic(() => import("./PortalNavBar"));
const LandingNavBar = dynamic(() => import("./LandingNavBar/LandingNavBar"));
const MainNavBar = dynamic(() => import("./MainNavBar/MainNavBar"));

const Header = ({ navbar = DEFAULT_NAVBAR, additionalQueryFields }) => {
  const navbarComponent = {
    [CLUB_NAVBAR]: (
      <ClubsNavBar additionalQueryFields={additionalQueryFields} />
    ),
    [DEFAULT_NAVBAR]: <MainNavBar />,
    [EMPTY_NAVBAR]: null,
    [PORTAL_NAVBAR]: <PortalNavBar />,
    null: <LandingNavBar />,
    LANDING_NAVBAR: <LandingNavBar />,
  };

  return (
    <>
      {navbarComponent[navbar]}

      <style jsx global>{`
        @import "./MainNavBar/MainNavBar.scss?ssssss";
        @import "./LandingNavBar/LandingNavBar.scss?1";
      `}</style>
    </>
  );
};

export { Header };
